export default {
  container: {
    marginTop: 4,
    paddingBottom: 4,
  },
  title: {
    marginBottom: 4,
  },
  italic: {
    fontStyle: 'italic',
  },
};
