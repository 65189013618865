export default {
  termsPrivacyWrapper: {
    marginTop: '24px',
    fontSize: '14px',
    lineHeight: 0,
  },
  defaultFontSize: {
    fontSize: '14px !important',
  }
};
