import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function CopyIcon({ fill = '#F9FAFB', ...rest }: SvgIconProps) {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14" {...rest}>
      <path
        d="M12.2859 0.0800934H3.84888C2.95068 0.0800934 2.21998 0.812993 2.21998 1.71389V2.21974H1.70873C0.810534 2.21974 0.079834 2.95264 0.079834 3.85404V12.2862C0.079834 13.1871 0.810534 13.92 1.70873 13.92H10.146C11.0471 13.92 11.78 13.1871 11.78 12.2862V11.7803H12.2859C13.187 11.7803 13.9202 11.0474 13.9202 10.146V1.71384C13.9201 0.812993 13.187 0.0800934 12.2859 0.0800934ZM10.78 12.2861C10.78 12.6357 10.4956 12.9199 10.146 12.9199H1.70873C1.36203 12.9199 1.07983 12.6357 1.07983 12.2861V3.85399C1.07983 3.50439 1.36208 3.21969 1.70873 3.21969H2.71998H10.146C10.4956 3.21969 10.78 3.50434 10.78 3.85399V11.2802V12.2861ZM12.9201 10.146C12.9201 10.4956 12.6357 10.7803 12.2858 10.7803H11.78V3.85399C11.78 2.95264 11.0471 2.21969 10.1459 2.21969H3.21993V1.71384C3.21993 1.36424 3.50218 1.08004 3.84883 1.08004H12.2858C12.6357 1.08004 12.9201 1.36424 12.9201 1.71384V10.146Z"
        fill={fill}
      />
    </SvgIcon>
  );
}

export default CopyIcon;
